<template>
  <div class="commodity-apply-order-list">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item class="menu-item"
                             v-model="searchType"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
        <van-icon class="search-icon"
                  name="scan"
                  @click="scanIconBtn" />
      </div>
      <div class="filter">
        <div class="filter-item"
             @click="warehouseClick">
          <span>{{ warehouseComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="filter-item">
          <span>待出库</span>
          <van-switch v-model="filter.delivery"
                      size="12px"
                      @change="switchChange" />
        </div>
      </div>
      <van-popup v-model="showWarehouse"
                 round
                 position="bottom">
        <custom-popup title="仓库"
                      value-key="name"
                      :columns="warehouseColumns"
                      @cancel="showWarehouse = false"
                      @confirm="warehouseConfirm"></custom-popup>
      </van-popup>
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id">
            <item-card class="list-item"
                       :title="item.applyNumber"
                       :status="parseStatus(item.pickStatus)">
              <div class="content">
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">申请人:</span>
                    <span class="value">{{ item.applicantName }}</span>
                  </div>
                  <div class="content-item">
                    <span class="name">提货人:</span>
                    <span class="value">{{ item.pickName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">请领单位:</span>
                    <span class="value">{{ item.organizationName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">联系电话:</span>
                    <span class="value">{{ item.pickMobileNumber }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">提货日期:</span>
                    <span class="value">{{ item.pickTime }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">申请时间:</span>
                    <span class="value">{{ item.creationTime }}</span>
                  </div>
                </div>
              </div>
              <template #footer>
                <div class="action">
                  <van-button class="detail-btn"
                              type="info"
                              size="small"
                              round
                              @click="itemClick(item.id)">
                    <span>
                      {{ item.pickStatus == "FINISHED" ? "查看" : "拣货" }}
                    </span>
                  </van-button>
                </div>
              </template>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityApplyOrderList.less";
import Filter from "ldap-filters";
import wxapi from "@/lib/wechat";
import { SUCCESS_CODE } from "@/apis/notification";
import { flatMap, find } from "lodash-es";
export default {
  name: "CommodityApplyOrderList",
  data() {
    return {
      searchType: "applyNumber",
      searchTypeOptions: [
        { text: "请领单号", value: "applyNumber" },
        { text: "提货人", value: "pickName" }
      ],
      searchContent: "",

      filter: {
        warehouse: "",
        delivery: null
      },

      showWarehouse: false,
      warehouseColumns: [],

      listStatus: {
        loading: true,
        finished: false
      },

      list: [],
      page: {
        pageNumber: 0,
        pageSize: 10
      },
      hasMore: false
    };
  },
  computed: {
    warehouseComputed() {
      if (!this.warehouseColumns.length) return "仓库名称";
      let item = find(this.warehouseColumns, item => item.id == this.filter.warehouse);
      return item ? item.name : "仓库名称";
    }
  },
  methods: {
    async getWarehouseList() {
      let params = {
        useType: "WAREHOUSE_KEEPER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseColumns = [{ id: "all", name: "全部仓库" }, ...ret.data];
    },
    async getClaimApplyList(sign = false, query) {
      let params = {
        useType: "WAREHOUSE_KEEPER",
        pickStatus: this.filter.delivery ? "NEW" : null,
        // startTime: this.filter.date.length ? `${this.filter.date[0]} 00:00:00`: null,
        // endTime: this.filter.date.length ? `${this.filter.date[1]} 23:59:59`: null,
        [this.searchType]: this.searchContent != "" ? this.searchContent : null,
        warehouseId: this.filter.warehouse ? this.filter.warehouse : null,
        status: "APPROVED",
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize
      };

      let sort = [{ fieldName: "creationTime", direction: "DESC" }];
      params.sort = encodeURI(JSON.stringify(sort));
      if (query) {
        params = query;
      }
      let ret = await this.$api.getClaimApplyList({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery() {
      let filters = [];
      if (this.filter.delivery) {
        let query = Filter.attribute("pickStatus").equalTo("NEW");
        filters.push(query);
      }

      if (this.searchContent != "") {
        let query = Filter.attribute(this.searchType).contains(this.searchContent);
        filters.push(query);
      }
      let query = Filter.attribute("status").equalTo("APPROVED");
      filters.push(query);
      if (this.filter.warehouse && this.filter.warehouse != "all") {
        let query = Filter.attribute("warehouseId").equalTo(this.filter.warehouse);
        filters.push(query);

        if (filters.length == 1) {
          return filters[0].toString();
        } else if (filters.length > 1) {
          return Filter.AND(filters).toString();
        }
      } else {
        let outFilters = [];
        if (filters.length) {
          let query = Filter.AND(filters);
          outFilters.push(query);
        }

        let query = Filter.OR([Filter.attribute("WAREHOUSEIDS").equalTo("")]);
        outFilters.push(query);

        if (outFilters.length == 1) {
          return outFilters[0].toString();
        } else if (outFilters.length > 1) {
          return Filter.AND(outFilters).toString();
        }
      }
    },

    warehouseClick() {
      this.showWarehouse = true;
    },
    switchChange() {
      this.getInitialData();
    },
    warehouseConfirm(arg) {
      this.filter.warehouse = arg.id == "all" ? null : arg.id;
      this.showWarehouse = false;
      this.getInitialData();
    },
    onSearch() {
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getClaimApplyList();
    },
    onLoad() {
      this.getClaimApplyList(true);
    },
    itemClick(id) {
      this.$router.push({
        name: "commodityPickingDetail",
        params: {
          id
        }
      });
    },
    addCommodity() {
      this.$router.push({
        name: "addCommodity"
      });
    },
    parseStatus(status) {
      if (status == "FINISHED") return "已出库";
      return "待出库";
    },
    init() {
      this.getInitialData();
    },

    scanIconBtn() {
      let query;
      wx.scanQRCode({
        needResult: 1,
        scanType: ["qrCode", "barCode"],
        success: res => {
          try {
            let query = res.resultStr;
            this.getClaimApplyList(false, query);
          } catch (err) {
            this.$notify({ message: "扫码失败", type: "warning" });
          }
        }
      });
      // query = {
      //   useType: "WAREHOUSE_KEEPER",
      //   applyNumber: "CA1660896418610",
      //   status: "APPROVED"
      // };
      // this.getClaimApplyList(false, query);
    }
  },
  mounted() {
    wxapi.ready(["scanQRCode"]).then(res => {
      console.log(res);
    });
  },
  filters: {
    parseStatus(status) {
      if (status == "FINISHED") return "已出库";
      return "待出库";
    }
  },
  created() {
    this.getWarehouseList();
    this.getClaimApplyList();
  }
};
</script>

<style></style>
